$(document).ready(function() {
//Scroll totop
//-----------------------------------------------
    $(window).scroll(function () {
        if ($(this).scrollTop() != 0) {
            $(".scrollToTop").fadeIn();
        } else {
            $(".scrollToTop").fadeOut();
        }
    });

    $(".scrollToTop").click(function () {
        $("body,html").animate({scrollTop: 0}, 800);
    });

    /* Scroll to any content on the page. Minus 100 px for hte floating header */
    $(".scrollToContent").click(function(event) {
        event.preventDefault();

        $hash = $(this).data('scroll');
        $('html, body').animate({
            'scrollTop': $('#' + $hash).offset().top
        }, 2000);
    });
});
