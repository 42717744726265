$(document).ready(function() {

    //Check if the homepage hero is available
    if ($('.homepage-hero-module').length > 0) {

        setTimeout(function () {
            var typed = new Typed('.homepage-typing', {
                stringsElement: '#typed-strings',
                typeSpeed: 70,
                backSpeed: 20,
                backDelay: 1500,
                smartBackspace: true,
                loop: true,
            });
        }, 2500);

        setTimeout(function () {
            $('.homepage-hero-module .intro').fadeOut();
        }, 1800);
    }
});