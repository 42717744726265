/* Functions for all pages */

/* Load item ont he page when teh user scrolls past them */
$(function () {
    var trigger = new ScrollTrigger({
        toggle: {
            visible: 'customFadeInUp'
        },
        offset: {
            y: 150
        },
        once: true
    });
});


/* Function for a flashing logo */
function toggleDash() {

    $('#logo-dash').fadeToggle(150);
    setTimeout(function() {
        toggleDash();
    }, 1000);
}

toggleDash();